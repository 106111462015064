"use client"
import styled from "@emotion/styled"
import { AppType, AppTypeProvider } from "contexts/AppTypeProvider"
import { LayoutProvider } from "contexts/LayoutProvider"
import { usePathname } from "next/navigation"
import { FC, Suspense, useEffect, useRef } from "react"
import Analytics from "reusable/Analytics"
import { Desktop, Mobile } from "ui"
import DesktopHeader from "./DesktopHeader"
import Footer from "./Footer"
import MobileHeader from "./MobileHeader"

const StyledMain = styled.div<{ isParking: boolean; isContent: boolean }>`
  flex-grow: 1;
  padding-top: 64px;
  overflow-x: hidden;

  ${(props) => props.theme.breakpoints.up("md")} {
    /* padding-top: ${(props) => (props.isContent ? "0px" : "98px")}; */
    padding-top: 98px;
    height: ${(props) => (props.isParking ? "100vh" : "auto")};
  }

  :focus {
    outline: 0;
  }
`

const SkipLink = styled.a`
  position: absolute;
  top: -40px;
  left: 0;
  background: #000;
  color: #fff;
  padding: 8px;
  z-index: 100;
  transition: top 0.3s ease;

  &:focus {
    top: 0;
  }
`

const Layout: FC<any> = ({ locale, children, menu_data, footer_data }) => {
  const mainRef = useRef<HTMLDivElement>(null)
  const pathname = usePathname()

  useEffect(() => {
    if (typeof window !== "undefined" && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister()
        }
      })
    }
  }, [])

  return (
    <LayoutProvider>
      <AppTypeProvider initialAppType={AppType.Corporate}>
        <SkipLink href="#mainContent">דלג לתוכן</SkipLink>
        <Suspense>
          <Analytics />
        </Suspense>
        <Desktop>
          <DesktopHeader data={menu_data} locale={locale} />
        </Desktop>
        <Mobile>
          <MobileHeader data={menu_data} locale={locale} />
        </Mobile>
        <>
          <StyledMain
            isContent={pathname.includes("content")}
            isParking={pathname === "/parking" || pathname === "/en/parking"}
            id="mainContent"
            ref={mainRef}
            role="main"
          >

            {children}

          </StyledMain>
          <Footer data={footer_data} />
        </>
      </AppTypeProvider>
    </LayoutProvider >
  )
}

export default Layout

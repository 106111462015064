import { gql } from "@apollo/client"

type Props = {
  contentType?: string
  numberOfItems?: number
  arrayOfID?: number[]
  arrayOfKeys?: string[]
  arrayOfUrls?: string[]
  level?: number
  levelOfParent?: number
  categories?: boolean
}

function GET_COMPONENTS({
  contentType,
  numberOfItems,
  arrayOfID,
  level,
  categories,
  levelOfParent,
  arrayOfUrls,
  arrayOfKeys,
}: Props) {
  const query = `
query GetComponent(
  $route: String!
  ${categories ? `$categories: [String!]` : ""}
) {
  components: contentDescendantsByAbsoluteRoute(
    route: $route
    ${numberOfItems && `first: ${numberOfItems}`}
    where: { and: [${contentType ? `{ contentType: { alias: { eq: "${contentType}" } } },` : ""
    }
    ${arrayOfID && arrayOfID?.length > 0
      ? `{ id: { in: [${arrayOfID}] } },`
      : ""
    }
    ${arrayOfUrls && arrayOfUrls?.length > 0
      ? `{ url: { in: [${arrayOfUrls}] } },`
      : ""
    }
    ${arrayOfKeys && arrayOfKeys?.length > 0
      ? `{ key: { in: [${arrayOfKeys}] } },`
      : ""
    }
    ${level ? `{ level : { eq: ${level} } },` : ""}
    ${categories
      ? `{ parent : { level: {eq:${levelOfParent}}, name: {in: $categories } } },`
      : ""
    }

  ] }

  ) {
    edges {
      cursor
      node {
        name
        id
        url
        createDate
        absoluteUrl
        contentType {
          alias
        }
        parent {
          name
          level
          url
          id
          properties {
            propertyName: alias
            propertyValue: value {
              ...propertyValuesComponent
            }
          }
          parent {
            level
            name
            url
            id
            properties {
              propertyName: alias
              propertyValue: value {
                ...propertyValuesComponent
              }
            }
            parent {
              level
              name
              url
              id
              properties {
                propertyName: alias
                propertyValue: value {
                  ...propertyValuesComponent
                }
              }
              parent {
                level
                name
                url
                id
                properties {
                  propertyName: alias
                  propertyValue: value {
                    ...propertyValuesComponent
                  }
                }
                parent {
                  level
                  name
                  url
                  id
                  properties {
                    propertyName: alias
                    propertyValue: value {
                      ...propertyValuesComponent
                    }
                  }
                  parent {
                    name
                    level
                    url
                    id
                    properties {
                      propertyName: alias
                      propertyValue: value {
                        ...propertyValuesComponent
                      }
                    }
                  }
                }
              }
            }
          }
        }
        children {
          name
          url
          id
          contentType {
            alias
          }
          properties {
            propertyName: alias
            propertyValue: value {
              ...propertyValuesComponent
            }
          }
        }
        properties {
          propertyName: alias
          propertyValue: value {
            ...propertyValuesComponent
          }
          #editorAlias
        }
      }
    }
  }
}

fragment propertyEditorsComponent on PropertyValue {
  ...basicValue
  ...contentPicker
  ...dateTimePicker
  ...label
  ...mediaPicker
  ...memberPickerComponent
  ...multiUrlPicker
  ...nestedContentComponent
  ...richTextEditorComponent
  ...unsupportedEditor
  valueType: model
}

fragment dateTimePicker on PropertyValue {
  ... on BasicDateTimePicker {
    dateTime: value
  }
}

fragment basicValue on PropertyValue {
  ... on BasicPropertyValue {
    value: value
  }
}

fragment contentPicker on PropertyValue {
  ... on BasicContentPicker {
    contentList {
      id
      absoluteUrl
      key
      name
      url
      urlSegment
    }
  }
}

fragment label on PropertyValue {
  ... on BasicLabel {
    label: value
  }
}

fragment mediaPicker on PropertyValue {
  ... on BasicMediaPicker {
    mediaItems {
      id
      url
    }
  }
}

fragment memberPickerComponent on PropertyValue {
  ... on BasicMemberPicker {
    members {
      id
      name
      properties {
        alias
        value {
          ...memberPropertyEditorsComponent
        }
        editorAlias
      }
    }
  }
}

fragment memberPropertyEditorsComponent on PropertyValue {
  ...basicValue
  ...contentPicker
  ...dateTimePicker
  ...label
  ...mediaPicker
  ...slimMemberPicker
  ...multiUrlPicker
  ...nestedContentComponent
  ...richTextEditorComponent
  ...unsupportedEditor
  model
}

fragment slimMemberPicker on PropertyValue {
  ... on BasicMemberPicker {
    members {
      id
      name
    }
  }
}

fragment multiUrlPicker on PropertyValue {
  ... on BasicMultiUrlPicker {
    links {
      name
      target
      type
      url
    }
  }
}

fragment nestedContentComponent on PropertyValue {
  ... on BasicNestedContent {
    elements {
      properties {
        alias
        value {
          ...nestedContentPropertyEditorsComponent
        }
        editorAlias
      }
    }
  }
}

fragment nestedContentPropertyEditorsComponent on PropertyValue {
  ...basicValue
  ...contentPicker
  ...dateTimePicker
  ...label
  ...mediaPicker
  ...slimMemberPicker
  ...multiUrlPicker
  ...richTextEditorComponent
  ...unsupportedEditor
  model
}

fragment richTextEditorComponent on PropertyValue {
  ... on BasicRichText {
    richText: value
    sourceValue
  }
}

fragment unsupportedEditor on PropertyValue {
  ... on BasicUnsupportedPropertyValue {
    message
  }
}

fragment blockEditorComponent on PropertyValue {
  ... on BasicBlockListModel {
    blocks {
      blockType: contentAlias
      #settingsAlias
      properties: contentProperties {
        propertyName: alias
        #editorAlias
        propertyValue: value {
          ...propertyEditorsComponent
          ... on BasicBlockListModel {
            blocks {
              blockType: contentAlias
              properties: contentProperties {
                propertyName: alias
                #editorAlias
                propertyValue: value {
                  ...propertyEditorsComponent
                  ... on BasicBlockListModel {
                    blocks {
                      blockType: contentAlias
                      properties: contentProperties {
                        propertyName: alias
                        #editorAlias
                        propertyValue: value {
                          ...propertyEditorsComponent
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

fragment blockGridEditorComponent on PropertyValue {
  ... on BasicBlockGridModel {
    gridColumns
    blocks {
      contentProperties {
        value {
          ...propertyEditorsComponent
        }
      }
      settingsProperties {
        value {
          ...propertyEditorsComponent
        }
      }
      columnSpan
      rowSpan
      areas {
        alias
        rowSpan
        columnSpan
        blocks {
          contentProperties {
            value {
              ...propertyEditorsComponent
            }
          }
          settingsProperties {
            value {
              ...propertyEditorsComponent
            }
          }
          columnSpan
          rowSpan
          areas {
            alias
            rowSpan
            columnSpan
          }
        }
      }
    }
  }
}

fragment propertyValuesComponent on PropertyValue {
  ...propertyEditorsComponent
  ...blockEditorComponent
  ...blockGridEditorComponent
}
`
  return gql`
    ${query}
  `
}

export default GET_COMPONENTS

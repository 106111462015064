import { sendGTMEvent } from "@next/third-parties/google"

export const trackFooterLinkClicked = (category: string) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "Footer",
        category
    })
}
export const trackTopBarLinkClicked = (category: string) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "Top bar",
        category
    })
}
export const trackSocialMediaClicked = (category: "Facebook" | "Youtube") => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "social_media",
        category
    })
}
export const trackEventByNameAndCategory = (event_name: string, category: string) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name,
        category
    })
}
export const trackQuestionClicked = (category: string) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "FAQ",
        question: category
    })
}
export enum TrackMapSource {
    BODY = "Body",
    HEADER = "Header",
    MOBILE = "Mobile"
}
export const trackGoToMap = (category: TrackMapSource) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "FAQ",
        question: category
    })
}
export const trackEventByNameAndType = (event_name: string, type: string) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name,
        type
    })
}
export const trackArticle = (category: string, Article_position: string, Article_name: string) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "Articles",
        category,
        Article_position,
        Article_name
    })
}
export const trackShowMoreArticles = () => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "All_Articles",
    })
}
export const trackUserFocusFiledOnForm = (fieldName: string) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "Form",
        Field: fieldName
    })
}
export const trackFormSubmition = () => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "Form submit",
    })
}
export const trackEventName = (event_name: string) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name
    })
}
export const trackScrollerClick = (event_name: string, text: string, pageName: string) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: event_name,
        category: text,
        navigation: pageName
    })
}
export const trackMoreArticlesClick = (pageTitle: string) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "articles_side_bar",
        Article_name: "more_article",
        navigation: pageTitle
    })
}
export const trackArticleSidebarClick = (name: string, position: number, pageName: string) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "articles_side_bar",
        Article_name: name,
        Article_position: position.toString(),
        navigation: pageName
    })
}
export const trackArticleHomePageClick = (name: string, position: number) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "articles_home_page",
        Article_name: name,
        Article_position: position.toString(),
    })
}
export const trackMapNavigationToDest = (dest: string) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "Map_navigation",
        type: "waze",
        navigation: dest
    })
}
export const trackMapMoreInfoStation = (dest: string) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "Map_more_info",
        navigation: dest
    })
}
export enum MapOrList {
    MAP = "map",
    LIST = "list"
}
export const trackMapOrListDisplay = (type: MapOrList) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "Map_type",
        type: type
    })
}
export const trackMapSearch= (search_term: string) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "Map_search",
        search_term
    })
}
export const trackMapServiceFilter= (service: string) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "Map_service_filter",
        service
    })
}
export const trackStationInfoMap = (info_type: string) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "Station_info_map",
        info_type
    })
}
export const trackAppWalletInfo= (info_type: string) => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "app_wallet_info",
        info_type
    })
}
export const trackDownloadApp= () => {
    sendGTMEvent({
        event: "ga4_auto_event",
        event_name: "Download app",
    })
}



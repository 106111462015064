"use client"
import { useQuery as useApolloQuery } from "@apollo/client"
import styled from "@emotion/styled"
import {
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Modal,
  Paper,
} from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { GetComponentEdgesQuery } from "apollo"
import { LayoutContext } from "contexts/LayoutProvider"
import GET_COMPONENTS from "lib/GET_COMPONENT"
import Link from "next/link"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { NewText, SubmitSearchIcon, XIcon } from "ui"
import { sharedColors } from "ui/theme/colors"
import { extractProperty } from "utils"
import { fetchResults } from "./SearchAutocomplete"

const StyledInputBase = styled(InputBase)`
  & .MuiInputBase-input {
    color: #153e69;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 18px;
      line-height: 25.2px;
    }
  }
`
const SearchBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
`

const ResultsContainer = styled.div`
  overflow-y: scroll;
  display: flex;
  /* margin-top: 24px; */
  flex-direction: column;
  z-index: 1000000000;
  background-color: white;
  border-radius: 24px;
`
const Result = styled.div`
  padding: 12px 16px 12px 16px;
  width: 100%;
  height: 48px;
  color: #153e69;
  font-size: 16px;
  line-height: 24px;
  z-index: 1000000000;
  border-bottom: 1px dashed #153e694d;
  :hover {
    background-color: #153e690f;
  }
`

const StyledContainer = styled.div`
  height: 100%;
  background-color: white;
  margin-top: 63px;
  padding-top: 32px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`
const StyledIconButton = styled(IconButton)`
  padding: 0px;
`

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`

const FormContainer = styled.div`
  padding: 20px;
`
const StyledIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`
const StyledX = styled(IconButton)`
  /* display: flex; */
  /* align-items: stretch; */
  padding: 0 4px;
  margin: 0;
  border-radius: 0;
  height: 100%;
  border-right: 1px dashed #153e694d;
`

type Item = {
  id: number
  name: string
  urlSegment: string
  sortOrder: number
  level: number
  path: string
  createDate: string
  updateDate: string
  key: string
  url: string
}

type QueryResults = {
  items: Item[]
  pageSize: number
  pageIndex: number
  totalRecords: number
  term: string
}
function getNonUmbracoURL(url?: string) {
  if (!url) {
    return ""
  }
  return url.replace(/.*\/pages/, "")
}

type Props = {
  setMobileSearch: Dispatch<SetStateAction<boolean>>
  mobileSearch: boolean
  searchPath?: string
}
export const SearchMobile = ({
  searchPath,
  setMobileSearch,
  mobileSearch,
}: Props) => {
  const inputRef = useRef<HTMLFormElement | null>(null)
  const router = useRouter()
  const searchParams = useSearchParams().get("searchString")
  const pathname = usePathname()
  const defaultText = pathname === "/search" && searchParams ? searchParams : ""
  const [inputText, setInputText] = useState(defaultText)
  const [searchText, setSearchText] = useState(defaultText)
  const { GRAPHQL_URL } = useContext(LayoutContext)
  const { data, isLoading, isError, isSuccess } = useQuery<QueryResults>({
    queryKey: [searchText],
    queryFn: () =>
      fetchResults(
        GRAPHQL_URL?.replace("/graphql", ""),
        searchText,
        searchPath,
      ),

    enabled: !!searchText,
    refetchOnWindowFocus: false,
  })

  const keyArra = data?.items?.map((el) => el.key) || []
  const formattedArr = keyArra?.length === 0 ? ["none"] : keyArra
  const { data: apolloData, loading } = useApolloQuery<GetComponentEdgesQuery>(
    GET_COMPONENTS({
      numberOfItems: 200,
      arrayOfKeys: formattedArr.map((el) => `"${el}"`),
    }),
    {
      variables: {
        route: "/pazcorp/pages/",
      },
      skip: !searchText,
      context: {
        uri: GRAPHQL_URL,
      },
    },
  )
  const isOpen = isError || (isSuccess && !loading)
  const finalDataUnsorted =
    apolloData?.components?.edges?.map((el) => ({
      url: el.node.url!,
      name: extractProperty(el.node, "pageName") || el.node.name,
    })) || []
  const finalData = finalDataUnsorted
    .sort((a, b) => {
      if (a.name.toLowerCase() === searchText.toLowerCase()) {
        return -1
      }
      if (b.name.toLowerCase() === searchText.toLowerCase()) {
        return 1
      }
      return 0
    })
    .filter((el) => el.name !== "Blocks example")
  const clearHandle = () => {
    setInputText("")
    setSearchText("")
  }
  const moveToSeachPage = () => {
    setMobileSearch(false)
    if (!inputText) {
      return
    }
    router.push(`/search?searchString=${inputText}`)
  }
  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchText(inputText)
    }, 1000)

    return () => {
      clearTimeout(handler)
    }
  }, [inputText])
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])
  return (
    <Modal open={mobileSearch} onClose={() => setMobileSearch(false)}>
      <StyledContainer>
        <StyledHeader>
          <NewText
            desktopFontSize={18}
            component={"h2"}
            mobileFontSize={18}
            mobileLineHeight={"25.2px"}
            color="black"
            desktopFontWeight={500}
          >
            חיפוש באתר
          </NewText>
          <StyledIconButton
            onClick={() => {
              clearHandle()
              setMobileSearch(false)
            }}
          >
            <XIcon />
          </StyledIconButton>
        </StyledHeader>
        <FormContainer>
          <Paper
            component="form"
            sx={{
              p: 0,
              pt: "4px",
              pb: "4px",
              borderRadius: "24px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              border: "1px solid rgba(185, 197, 210, 0.70)",
              boxShadow: "none",
            }}
            onSubmit={(e) => {
              e.preventDefault()
              setSearchText(inputText)
            }}
          >
            <StyledInputBase
              sx={{
                ml: "8px",
                flex: 1,
                color: sharedColors.darkBlue,
              }}
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              inputProps={{
                "aria-label": "הזן ערך כדי לחפש",
                "aria-expanded": isOpen,
                "aria-controls": "autocomplete-results",
              }}
              inputRef={inputRef}
            />
            <StyledIcons>
              {inputText.length ? (
                <StyledX onClick={clearHandle}>
                  <XIcon color="#5C6474" width={18} height={18} />
                </StyledX>
              ) : null}

              <Button
                type="submit"
                style={{
                  padding: 0,
                  margin: 0,
                  minHeight: 0,
                  minWidth: 0,
                }}
                aria-label="חיפוש"
                disabled={isLoading || loading}
                onClick={moveToSeachPage}
              >
                <IconButton
                  tabIndex={-1}
                  color="primary"
                  sx={{ p: "0px", pr: "8px" }}
                  aria-label="directions"
                >
                  {isLoading || loading ? (
                    <CircularProgress color="inherit" size={32} />
                  ) : (
                    <SubmitSearchIcon width={32} height={32} blue />
                  )}
                </IconButton>
              </Button>
            </StyledIcons>
          </Paper>
        </FormContainer>

        <SearchBox>
          {isOpen ? (
            <ResultsContainer>
              {finalData && (
                <>
                  {finalData.map((el) => (
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      href={getNonUmbracoURL(
                        el.url.includes("home") ? "/" : el.url,
                      )}
                      onClick={() => {
                        clearHandle()
                        setMobileSearch(false)
                      }}
                      key={el.name}
                    >
                      <Result>{el.name}</Result>
                    </Link>
                  ))}
                </>
              )}
              {finalData.length === 0 && <Result>אין תוצאות</Result>}
            </ResultsContainer>
          ) : null}
        </SearchBox>
      </StyledContainer>
    </Modal>
  )
}

export default SearchMobile

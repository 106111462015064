import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import { FetchMenusQuery } from "apollo"
import Image from "next/image"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { useContext, useState } from "react"
import { SearchIcon, XIcon } from "ui/icons"

import { LayoutContext } from "contexts/LayoutProvider"
import colors from "theme/colors"
import { NewText } from "ui"
import { extractMenuProperties } from "utils"
import { getNonUmbracoURL } from "utils/utils"
import DesktopNavlink from "./DesktopNavLink"
import SearchAutocomplete from "./SearchResult/SearchAutocomplete"
import { StyledList } from "./SideMenu"

const StyledListHeader = styled(StyledList)`
  margin-inline-end: auto;
  display: flex;
  height: 98px;
  overflow-y: hidden;
  box-sizing: border-box;
  margin: 0;
   & > li {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
`
const StyledLink = styled(Link) <{ selected?: boolean }>`
  display: flex;
  ${(props) => props.theme.breakpoints.up("xl")} {
    margin-right: 65px;
  }
  margin-right: 20px;
`

const StyledRelative = styled.header`
  @media print {
    display: none;
  }
  position: relative;
  z-index: 30;
`
const StyledHeader = styled.div`
  position: fixed;
  z-index: 100001;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  height: 64px;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px;
  box-shadow: 0px 2px 8px 0px rgba(130, 136, 148, 0.25);

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    height: 98px;
    padding: 0;
  }
`

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  align-items: center;
  padding: 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0 20px;
  }
  ${(props) => props.theme.breakpoints.up("xl")} {
    padding: 0 60px;
  }
`

const StyledLanguageSwitcher = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 20px;
  a {
    color: ${colors.black};
    text-decoration: none;
  }
`

const StyledIconButton = styled(IconButton)`
  padding: 0px;
  margin-right: 50px;
  ${(props) => props.theme.breakpoints.up("xl")} {
    margin-right: 80px;
  }
  border-radius:0;
  :focus-visible{
    outline:2px solid blue;
  }
  margin-left: 20px;
`

function DesktopHeader({
  locale,
  data,
}: {
  locale: string
  data: FetchMenusQuery
}) {
  const { searchPath } = useContext(LayoutContext)
  const [searchIsActive, setSearchIsActive] = useState(false)
  const pathname = usePathname()
  let hrefValue = ""
  if (locale === "he") {
    if (pathname.includes("investor-relations")) {
      hrefValue = "/en/investor-relations"
    } else {
      hrefValue = "/en/about-us"
    }
  } else if (locale === "en") {
    hrefValue = "/"
  }

  const menuDesktop = data?.menu_desktop?.nodes
  return (
    <StyledRelative role="banner">
      <StyledHeader>
        <StyledContainer>
          <StyledLink
            href={locale === "en" ? "/en/about-us" : "/"}
            locale={locale}
          >
            <Image
              src={
                locale === "en" ? "/Logo/endesktop.svg" : "/Logo/desktop.svg"
              }
              alt="פז, לעמוד הבית"
              width={105}
              height={64}
              priority
            />
          </StyledLink>

          {!searchIsActive ? (
            <nav style={{ width: "100%" }}>
              <StyledListHeader>
                {menuDesktop?.map((item: any, index: number) => {
                  const { title, url, blank } = extractMenuProperties(item)
                  const selected = decodeURI(pathname) + "/" === getNonUmbracoURL(url) ||
                    decodeURI(pathname) === getNonUmbracoURL(url)
                  return (
                    <DesktopNavlink
                      blank={blank}
                      key={index.toString()}
                      href={getNonUmbracoURL(url)}
                      selected={
                        selected
                      }
                      title={title}
                      subCategories={item?.children}
                      index={index}
                    />
                  )
                })}
              </StyledListHeader>
            </nav>
          ) : (
            <SearchAutocomplete
              setSearchIsActive={setSearchIsActive}
              searchPath={searchPath}
            />
          )}
          {locale === "he" && (
            <StyledIconButton
              disableRipple={true}
              aria-label={searchIsActive ? 'סגירת חיפוש' : 'חיפוש'}
              aria-expanded={searchIsActive}
              onClick={() => setSearchIsActive(!searchIsActive)}
            >
              {searchIsActive ? (
                <XIcon />
              ) : (
                <SearchIcon />
              )}
            </StyledIconButton>
          )}

          <StyledLanguageSwitcher >
            <Link href={hrefValue} locale={locale === "en" ? "he" : "en"} aria-label="לאתר באנגלית">
              <NewText
                component="p"
                desktopFontSize={18}
                desktopLineHeight={"118.5%"}
              >
                {locale === "en" ? "Heb" : "Eng"}
              </NewText>
            </Link>
          </StyledLanguageSwitcher>
        </StyledContainer>
      </StyledHeader>
    </StyledRelative>
  )
}

export default DesktopHeader

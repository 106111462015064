import { sendGTMEvent } from "@next/third-parties/google"
import { Attribute } from "api"

export const pageview = (url: string) => {
  sendGTMEvent({
    event: "pageview",
    page: url,
  })
}
export const trackLeadSubmit = (
  type: "שירות לקוחות" | "פזומט",
  subject: string
) => {
  sendGTMEvent({
    event: "lead_submit",
    type,
    subject,
  })
}

export const trackSearchAutocompleteFetch = (
  category: string,
  action: string[] | string,
  label: string = "top_bar",
) => {
  sendGTMEvent({
    event: "ga4_auto_event",
    event_name: "search_autocomplete",
    category,
    action,
    label,
  })
}

export const trackSearchLocation = (subject: string) => {
  sendGTMEvent({
    event: "ga4_auto_event",
    event_name: "search_location",
    subject
  })
}
export const trackSearchBarStations = (text: string) => {
  sendGTMEvent({
    event: "ga4_auto_event",
    event_name: "search_bar_stations",
    text
  })
}
export const trackStationsFilter = (attrs: Attribute[]) => {
  const type = attrs.filter(attr => attr.category === "שירותי רכב")
  const category = attrs.filter(attr => attr.category === "yellow")
  const subject = attrs.filter(attr => attr.category === "סופרים")
  sendGTMEvent({
    event: "ga4_auto_event",
    event_name: "stations_filter",
    type,
    category,
    subject
  })
}
export const trackStationsResetFilter = () => {
  sendGTMEvent({
    event: "ga4_auto_event",
    event_name: "stations_reset_filters"
  })
}
export const trackCareerMoreInfo = (jobName: string, companies: string[], date: string, locations: string[]) => {
  sendGTMEvent({
    event: "ga4_auto_event",
    event_name: "career_more_info",
    type: jobName,
    subject: companies.join(","),
    date: date,
    location: locations.join(",")
  })
}
export const trackCareerShare = (jobName: string, companies: string[], date: string, locations: string[]) => {
  sendGTMEvent({
    event: "ga4_auto_event",
    event_name: "career_share",
    type: jobName,
    subject: companies.join(","),
    date: date,
    location: locations.join(",")
  })
}
export const trackCareerShareMethodOnMobile = (subject: string) => {
  sendGTMEvent({
    event: "ga4_auto_event",
    event_name: "career_share_method",
    subject: subject,
  })
}
export const trackCareerRemoveFilter = (type: string, location: string, category: string, event_name: string) => {
  sendGTMEvent({
    event: "ga4_auto_event",
    event_name: event_name,
    type,
    location,
    category
  })
}
export const trackCareerFreeSearch = (subject: string) => {
  sendGTMEvent({
    event: "ga4_auto_event",
    event_name: "career_free_search",
    subject
  })
}
export const trackJobSubmit = (subject: string) => {
  sendGTMEvent({
    event: "ga4_auto_event",
    event_name: "career_job_submit",
    subject
  })
}
export const trackJobShare = (category: string, action: string) => {
  sendGTMEvent({
    event: "ga4_auto_event",
    event_name: "share job",
    category,
    action,
  })
}

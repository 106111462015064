import styled from "@emotion/styled"
import { Box, List } from "@mui/material"
import { useLocale } from "next-intl"
import Image from "next/image"
import Link from "next/link"
import { Desktop, FooterList, Mobile, NewText } from "ui"

import { GetFootersQuery } from "apollo"
import colors from "theme/colors"
import { extractProperty } from "utils"

import { getNonUmbracoURL } from "utils/utils"
import { StyledLink } from "./SideMenu"

const StyledFooter = styled.footer`
  @media print {
    display: none;
  }
  min-height: 583px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.darkBlue};
  ${(props) => props.theme.breakpoints.up("md")} {
    min-height: 563px;
  }
  position: relative;
  z-index: 2;
`

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 60px 0px 40px 0px;
  }
`

const StyledLinkList = styled.ul`
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    gap: 16px;
  }
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
  }
`

const StyledEnvAndBuildTime = styled(Box)`
  margin-top: 15px;
`

// const StyledMint = styled(Link)`
//   height: 45px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   text-decoration: none;
//   background-color: rgba(252, 188, 0, 1);
//   gap: 10px;
// `

const StyledLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 24px;

  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    border-bottom: 1px solid ${(props) => props.theme.colors.bluishGray};
    margin-bottom: 24px;
  }
`

const StyledGrid = styled.nav`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  ${(props) => props.theme.breakpoints.up("md")} {
     gap: 50px;
    display: flex;
  > * {
    gap: 16px;
    flex: 1 1 auto;
    width: auto;
   }
  }
`

const StyledLast = styled.div<{ en?: boolean }>`
  margin-top: 24px;
  ${(props) => props.theme.breakpoints.down("md")} {
    ${(props) =>
    props.en &&
    `
    margin:0;
    gap:17px;
    `}
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 0;
  width: 100%;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    border-top: 1px solid ${(props) => props.theme.colors.bluishGray};
  }
`

const StyledSocials = styled.div`
  display: flex;
  gap: 24px;
`

const StyledWithBorders = styled.div<{ en?: boolean }>`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 330px;
  padding: 20px 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0px;
    gap: 28px;
    max-width: 100%;
  }
  a {
    text-decoration: none;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 300px;
    ${(props) => props.theme.breakpoints.up("md")} {
      padding: 0px;
      gap: 28px;
      max-width: 100%;
    }
  }
`

const StyledLine = styled.div`
  height: 20px;
  width: 1px;
  background: ${(props) => props.theme.colors.bluishGray};
  opacity: 0.3;
`
const CustomContainer = styled.div`
  padding: 0 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0 40px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    padding: 0 220px;
  }
`
interface Props {
  data: GetFootersQuery
}

function Footer({ data }: Props) {
  const locale = useLocale()

  const desktopFooterData = data?.footer_desktop
  const mobileFooterData = data?.footer_mobile

  if (!data || !desktopFooterData || !mobileFooterData) {
    return null
  }

  const footerTitleDesktop = extractProperty(desktopFooterData, "footerTitle")
  const footerTitleMobile = extractProperty(mobileFooterData, "footerTitle")

  const rightsTextDesktop = extractProperty(desktopFooterData, "rightsText")
  const rightsTextMobile = extractProperty(mobileFooterData, "rightsText")

  const footerBottomLinksDesktop = extractProperty(
    desktopFooterData,
    "footerBottomLinks",
  )
  const footerBottomLinksMobile = extractProperty(
    mobileFooterData,
    "footerBottomLinks",
  )

  const desktopUrls = extractProperty(footerBottomLinksDesktop[0], "urls") || []
  const mobileUrls = extractProperty(footerBottomLinksMobile[0], "urls") || []

  //dddd

  const socialLinksDesktop = extractProperty(desktopFooterData, "socialLinks")
  const socialLinksMobile = extractProperty(mobileFooterData, "socialLinks")

  const desktopSocialUrls = extractProperty(socialLinksDesktop[0], "urls")
  const mobileSocialUrls = extractProperty(socialLinksMobile[0], "urls")

  const desktopItems = extractProperty(desktopFooterData, "columBlock")
  const mobileItems = extractProperty(mobileFooterData, "columBlock")

  return (
    <StyledFooter role="contentinfo">
      <CustomContainer>
        <StyledList>
          <StyledLogoContainer>
            <Desktop>
              <StyledLink href={`/`} locale={locale}>
                <Image
                  src={
                    locale === "en"
                      ? "/Logo/endesktop.svg"
                      : "/Logo/desktop.svg"
                  }
                  alt="לעמוד הבית של פז"
                  width={105}
                  height={64}
                />
              </StyledLink>
            </Desktop>
            <Mobile>
              <StyledLink href={`/`} locale={locale}>
                <Image
                  src={
                    locale === "en" ? "/Logo/enmobile.svg" : "/Logo/mobile.svg"
                  }
                  alt="לעמוד הבית של פז"
                  width={65}
                  height={40}
                />
              </StyledLink>
            </Mobile>
            <Desktop>
              <NewText
                mobileFontSize={28}
                desktopFontSize={36}
                component="p"
                desktopLineHeight="140%"
                desktopFontWeight="500"
                color={colors.white}
              >
                {footerTitleDesktop}
              </NewText>
            </Desktop>
            <Mobile>
              <NewText
                mobileFontSize={28}
                desktopFontSize={36}
                component="p"
                desktopLineHeight="140%"
                desktopFontWeight="500"
                color={colors.white}
              >
                {footerTitleMobile}
              </NewText>
            </Mobile>
          </StyledLogoContainer>
          <Desktop>
            <StyledGrid role="navigation">
              {Array.isArray(desktopItems) &&
                desktopItems.map((main: any, index: number) => {
                  const title = extractProperty(main, "title")
                  const mainCategoryLink = extractProperty(
                    main,
                    "mainCategoryLink",
                  )
                  const children = extractProperty(main, "urls")
                  return (
                    <StyledLinkList key={index.toString()}>
                      {!children.length ? (
                        <Link
                          href={
                            getNonUmbracoURL(mainCategoryLink?.[0]?.url) || "#"
                          }
                        >
                          <NewText
                            desktopFontSize={16}
                            component="p"
                            desktopLineHeight="150%"
                            desktopFontWeight="500"
                            color={colors.white}
                          >
                            {title}
                          </NewText>
                        </Link>
                      ) : (
                        <NewText
                          desktopFontSize={16}
                          component="p"
                          desktopLineHeight="150%"
                          desktopFontWeight="500"
                          color={colors.white}
                        >
                          {title}
                        </NewText>
                      )}
                      {children.map((item: any, ind: number) => {
                        const link = extractProperty(item, "link")
                        const linkTitle = extractProperty(item, "linkTitle")
                        return (
                          <Link
                            key={ind.toString()}
                            href={getNonUmbracoURL(link?.[0]?.url)}
                          >
                            <NewText
                              key={ind}
                              desktopFontSize={14}
                              component="li"
                              desktopLineHeight="20px"
                              desktopFontWeight="400"
                              color={colors.white}
                            >
                              {linkTitle}
                            </NewText>
                          </Link>
                        )
                      })}
                    </StyledLinkList>
                  )
                })}
            </StyledGrid>
          </Desktop>
          <Mobile>
            <List
              sx={{
                width: "100%",
                borderBottom: `1px solid ${colors.bluishGray}1E`,
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {Array.isArray(mobileItems) &&
                mobileItems?.map((item: any, index: number) => {
                  const title = extractProperty(item, "title")
                  const mainCategory = extractProperty(item, "mainCategoryLink")
                  const singleUrl = mainCategory?.[0]?.url
                  const childrenUnsorted = extractProperty(item, "urls")
                  const childrenSorted = childrenUnsorted.map((it: any) => {
                    const link = extractProperty(it, "link")
                    const linkTitle = extractProperty(it, "linkTitle")

                    return {
                      title: linkTitle,
                      url: getNonUmbracoURL(link?.[0]?.url),
                      singleUrl,
                    }
                  })

                  return (
                    <FooterList
                      locale={locale}
                      key={index.toString()}
                      title={title}
                      singlUrl={singleUrl}
                      children={childrenSorted}
                    />
                  )
                })}
            </List>
          </Mobile>
          <StyledLast en={locale === "en"}>
            <Desktop>
              <NewText
                desktopFontSize={14}
                component="li"
                desktopLineHeight="20px"
                desktopFontWeight="400"
                color={colors.white}
              >
                {rightsTextDesktop}
              </NewText>
            </Desktop>
            <Mobile>
              <NewText
                desktopFontSize={14}
                component="li"
                desktopLineHeight="20px"
                desktopFontWeight="400"
                color={colors.white}
              >
                {rightsTextMobile}
              </NewText>
            </Mobile>
            <Desktop>
              <StyledWithBorders>
                {desktopUrls.map((item: any, index: number) => {
                  const link = extractProperty(item, "link")
                  const linkTitle = extractProperty(item, "linkTitle")

                  return (
                    <Link
                      href={getNonUmbracoURL(link?.[0]?.url)}
                      key={index.toString()}
                    >
                      <NewText
                        desktopFontSize={14}
                        component="p"
                        desktopLineHeight="20px"
                        desktopFontWeight="400"
                        color={colors.white}
                      >
                        {linkTitle}
                      </NewText>
                      {index !== desktopUrls.length - 1 && <StyledLine />}
                    </Link>
                  )
                })}
              </StyledWithBorders>
            </Desktop>
            <Mobile>
              <StyledWithBorders en={locale === "en"}>
                {mobileUrls.map((item: any, index: number) => {
                  const link = extractProperty(item, "link")
                  const linkTitle = extractProperty(item, "linkTitle")
                  return (
                    <Link
                      href={getNonUmbracoURL(link?.[0]?.url)}
                      key={index.toString()}
                    >
                      <NewText
                        desktopFontSize={14}
                        component="p"
                        desktopLineHeight="20px"
                        desktopFontWeight="400"
                        color={colors.white}
                      >
                        {linkTitle}
                      </NewText>
                      {index !== mobileUrls.length - 1 && <StyledLine />}
                    </Link>
                  )
                })}
              </StyledWithBorders>
            </Mobile>
            <Desktop>
              <StyledSocials>
                {desktopSocialUrls.map((item: any, index: number) => {
                  const link = extractProperty(item, "link")
                  const linkImage = extractProperty(item, "linkImage")
                  return (
                    <Link
                      key={index.toString()}
                      style={{ width: 32, height: 32 }}
                      href={getNonUmbracoURL(link?.[0]?.url)}
                    >
                      <Image
                        src={linkImage.replace("http", "https")}
                        width={32}
                        height={32}
                        alt={`Link to ${link?.[0]?.url.includes("youtube") ? "Youtube" : "Facebook"}`}
                      />
                    </Link>
                  )
                })}
              </StyledSocials>
            </Desktop>
            <Mobile>
              <StyledSocials>
                {mobileSocialUrls.map((item: any, index: number) => {
                  const link = extractProperty(item, "link")
                  const linkImage = extractProperty(item, "linkImage")
                  return (
                    <Link
                      key={index.toString()}
                      style={{ width: 32, height: 32 }}
                      href={getNonUmbracoURL(link?.[0]?.url)}
                    >
                      <Image
                        src={linkImage.replace("http", "https")}
                        width={32}
                        height={32}
                        alt={link?.[0]?.name}
                      />
                    </Link>
                  )
                })}
              </StyledSocials>
            </Mobile>
          </StyledLast>
          {process.env.ENV !== "production" && (
            <StyledEnvAndBuildTime>
              <NewText
                desktopFontSize={14}
                component="p"
                desktopLineHeight="20px"
                desktopFontWeight="400"
                color={colors.white}
              >
                {process.env.ENV}{" "}
                {process.env.BUILD_TIMESTAMP &&
                  `- ${new Date(
                    Number(process.env.BUILD_TIMESTAMP) * 1000 || "",
                  ).toLocaleString()}`}
              </NewText>
            </StyledEnvAndBuildTime>
          )}
        </StyledList>
      </CustomContainer>
      {/* <StyledMint href="https://mintapp.co.il">
        <NewText
          desktopFontSize={14}
          component="p"
          desktopLineHeight="20px"
          desktopFontWeight="400"
          color={colors.black}
        >
          Created by <strong>Aman UI,</strong> Developed by{" "}
          <strong>Mint</strong>
        </NewText>
      </StyledMint> */}
    </StyledFooter>
  )
}

export default Footer

import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import { FetchMenusQuery } from "apollo"
import Image from "next/image"
import { usePathname } from "next/navigation"
import { useContext, useState } from "react"
import { SearchIcon, XIcon } from "ui/icons"

import { LayoutContext } from "contexts/LayoutProvider"
import { SearchMobile } from "./SearchResult/SearchMobile"
import SideMenu, { StyledButton, StyledLink } from "./SideMenu"

const StyledRelative = styled.header`
  position: relative;
  z-index: 30;
  @media print {
    display: none;
  }
`
const StyledHeader = styled.div<{ isOpen: boolean }>`
  position: fixed;
  z-index: 100001;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  height: 64px;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px;
  box-shadow: ${(props) =>
    !props.isOpen ? "0px 2px 8px 0px rgba(130, 136, 148, 0.25)" : null};

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    height: 98px;
    padding: 0;
  }
`

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  align-items: center;
  padding: 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding: 0 60px;
  }
`

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "mobile",
}) <{ mobile: boolean }>`
  padding: 0px;
  margin-right: 25px;
  display: ${({ mobile }) => (mobile ? "block" : "none")};
  ${(props) => props.theme.breakpoints.up("md")} {
    display: ${({ mobile }) => (!mobile ? "block" : "none")};
  }
`

function MobileHeader({
  locale,
  data,
}: {
  locale: string
  data: FetchMenusQuery
}) {
  const [open, setOpen] = useState<boolean>(false)
  const [searchIsActive, setSearchIsActive] = useState(false)
  const [mobileSearch, setMobileSearch] = useState(false)
  const { searchPath } = useContext(LayoutContext)
  const pathname = usePathname()
  let hrefValue = ""
  if (locale === "he") {
    if (pathname.includes("investor-relations")) {
      hrefValue = "/en/investor-relations"
    } else {
      hrefValue = "/en/about-us"
    }
  } else if (locale === "en") {
    hrefValue = "/"
  }

  return (
    <StyledRelative role="banner">
      <StyledHeader isOpen={open}>
        <StyledContainer>
          <StyledButton
            aria-expanded={open ? true : false}
            aria-label={open ? "סגירת תפריט" : "פתיחת תפריט"}
            onClick={() => setOpen((prev) => !prev)}
          >
            <Image
              src={open ? "/icons/close.svg" : "/icons/burger.svg"}
              alt="burger"
              width={24}
              height={24}
              priority
            />
          </StyledButton>
          <StyledLink
            href={locale === "en" ? "/en/about-us" : "/"}
            locale={locale}
          >
            <Image
              src={locale === "en" ? "/Logo/enmobile.svg" : "/Logo/mobile.svg"}
              alt="לעמוד הבית של פז"
              width={65}
              height={40}
            />
          </StyledLink>
          {locale === "en" && <div style={{ width: 36 }}></div>}
          {locale === "he" && (
            <StyledIconButton
              mobile={false}
              onClick={() => setSearchIsActive(!searchIsActive)}
              aria-label={searchIsActive ? 'סגירת חיפוש' : 'חיפוש'}
            >
              {searchIsActive ? (
                <XIcon />
              ) : (
                <SearchIcon />
              )}
            </StyledIconButton>
          )}
          {mobileSearch ? (
            <SearchMobile
              searchPath={searchPath}
              mobileSearch={mobileSearch}
              setMobileSearch={setMobileSearch}
            />
          ) : null}

          {locale === "he" && (
            <StyledIconButton
              mobile={true}
              aria-label="חיפוש"
              aria-expanded={searchIsActive}
              onClick={() => setMobileSearch((el) => !el)}
            >
              <SearchIcon />
            </StyledIconButton>
          )}
        </StyledContainer>
      </StyledHeader>
      <SideMenu
        menuItems={data}
        open={open}
        setOpen={setOpen}
        hrefValue={hrefValue}
      />
    </StyledRelative>
  )
}

export default MobileHeader

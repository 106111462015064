"use client"
import { useQuery as useApollogQuery } from "@apollo/client"
import styled from "@emotion/styled"
import {
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
} from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { GetComponentEdgesQuery } from "apollo/generated/graphql"
import { LayoutContext } from "contexts/LayoutProvider"
import GET_COMPONENTS from "lib/GET_COMPONENT"
import Link from "next/link"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { trackSearchAutocompleteFetch } from "reusable/gtm"
import { SubmitSearchIcon, useIsDesktop } from "ui"
import AlertMessageSROnly from "ui/AlertMessageSROnly"
import { sharedColors } from "ui/theme/colors"
import { extractProperty } from "utils"


const StyledInputBase = styled(InputBase)`
  & .MuiInputBase-input {
    color: #153e69;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 18px;
      line-height: 25.2px;
    }
  }
`

const Result = styled.div`
  padding: 12px 16px 12px 16px;
  width: 100%;
  height: 48px;
  color: #153e69;
  font-size: 16px;
  line-height: 24px;
  z-index: 1000000000;
  border-bottom: 1px solid #153e694d;
`

const StyledMenu = styled(Menu)`
& .MuiButtonBase-root{
  padding:0;
}
& .MuiList-root{
  padding:0;
}
& .MuiPaper-root{
  padding:0;
  padding: 16px;
  border:0;
  overflow: hidden;
  display: flex;
  /* margin-top: 24px; */
  flex-direction: column;
  z-index: 1000000000;
  background-color: white;
  border-radius: 24px;
  }`


type Item = {
  id: number
  name: string
  urlSegment: string
  sortOrder: number
  level: number
  path: string
  createDate: string
  updateDate: string
  key: string
  url: string
}

export type QueryResults = {
  items: Item[]
  pageSize: number
  pageIndex: number
  totalRecords: number
  term: string
}
function getNonUmbracoURL(url?: string) {
  if (!url) {
    return ""
  }
  return url.replace(/.*\/pages/, "")
}

export async function fetchResults(
  baseUrl: string,
  searchText: string,
  searchPath?: string,
) {
  const apiString = `${baseUrl}/mint/search?path=${searchPath}&term=${searchText}&pageIndex=0&pageSize=10`
  const apiResult = await fetch(apiString)
  const apiData = await apiResult.json()
  trackSearchAutocompleteFetch(
    searchText,
    apiData.items.length ? apiData : "לא נמצא",
  )
  return apiData
}

type Props = {
  setSearchIsActive: Dispatch<SetStateAction<boolean>>
  searchPath?: string
}

export const SearchAutocomplete = ({
  searchPath,
  setSearchIsActive,
}: Props) => {
  const router = useRouter()
  const searchParams = useSearchParams().get("searchString")
  const pathname = usePathname()
  const [open, setOpen] = useState(false)
  const defaultText =
    pathname === "/search" && searchParams ? searchParams || "" : ""
  const [inputText, setInputText] = useState(defaultText)
  const [searchText, setSearchText] = useState(defaultText)
  const { GRAPHQL_URL } = useContext(LayoutContext)
  // const isOpen = isError || (isSuccess && !loading)
  const isDesktop = useIsDesktop()
  const inputRef = useRef<HTMLFormElement | null>(null)
  const menuRef = useRef<HTMLDivElement | null>(null) // Ref for Menu component
  const handleOpen = () => {
    setOpen(true)
  };
  const handleClose = () => {
    setOpen(false)
    // if (inputRef.current) {
    //   inputRef.current.focus() // Keep focus on the input field
    // }
  };
  const paperRef = useRef<HTMLDivElement | null>(null) // Ref for Paper component

  const { data, isLoading, isError, isSuccess } = useQuery<QueryResults>({
    queryKey: [searchText],
    queryFn: () =>
      fetchResults(GRAPHQL_URL.replace("/graphql", ""), searchText, searchPath),

    enabled: !!searchText,
    refetchOnWindowFocus: false,
  })
  const keyArra = data?.items?.map((el) => el.key) || []
  const formattedArr = keyArra?.length === 0 ? ["none"] : keyArra
  const { data: apolloData, loading } = useApollogQuery<GetComponentEdgesQuery>(
    GET_COMPONENTS({
      numberOfItems: 200,
      arrayOfKeys: formattedArr.map((el) => `"${el}"`),
    }),
    {
      variables: {
        route: "/pazcorp/pages/",
      },
      context: {
        uri: GRAPHQL_URL,
      },
      skip: !searchText,
    },
  )

  const finalDataUnsorted =
    apolloData?.components?.edges?.map((el) => ({
      url: el.node.url!,
      name: extractProperty(el.node, "pageName") || el.node.name,
    })) || []
  const finalData = finalDataUnsorted
    .sort((a, b) => {
      const indexA = data?.items.findIndex((item) => item.url === a.url) || 0
      const indexB = data?.items.findIndex((item) => item.url === b.url) || 0
      return indexA - indexB
    })
    .filter((el) => el.name !== "Blocks example")


  const clearHandle = () => {
    setInputText("")
    setSearchText("")
  }
  const moveToSeachPage = () => {
    setSearchIsActive(false)
    if (!inputText) {
      return
    }
    router.push(`/search?searchString=${inputText}`)
  }
  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchText(inputText)
    }, 1000)

    return () => {
      clearTimeout(handler)
    }
  }, [inputText])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])
  useEffect(() => {
    if (isError || (isSuccess && !loading)) {
      handleOpen()
    }
  }, [isError, isSuccess, loading])
  return (
    <>
      <div
        ref={paperRef}
        style={{
          position: "relative",
          width: "90%",
          margin: "auto",
        }}
      >
        <Paper
          autoFocus
          component="form"
          aria-autocomplete="list"
          sx={{
            p: 0,
            pt: "4px",
            pb: "4px",
            borderRadius: "24px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            border: "1px solid rgba(185, 197, 210, 0.70)",
            boxShadow: "none",
          }}
          onSubmit={(e) => {
            e.preventDefault()
            setSearchText(inputText)
          }}
        >
          <StyledInputBase
            sx={{
              ml: "8px",
              flex: 1,
              color: sharedColors.darkBlue,
            }}
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            inputProps={{
              "aria-label": "השלמה אוטומטית, הזן ערך כדי לחפש",
              "aria-expanded": open,
              "aria-controls": "autocomplete-results",
            }}
            inputRef={inputRef}
            type={"search"}
            onKeyDown={(e) => {
              if (e.key === "Tab" && !e.shiftKey && open && finalData?.length > 0) {
                e.preventDefault()
                const firstMenuItem = menuRef.current?.querySelector<HTMLAnchorElement>('[role="link"]');
                if (firstMenuItem) {
                  firstMenuItem.focus();
                }
              }
            }
            }
            id="search-field"
          />
          <Button
            tabIndex={-1}
            type="submit"
            aria-label="חיפוש"
            style={{
              padding: 0,
              margin: 0,
              minHeight: 0,
              minWidth: 0,
            }}
            onClick={moveToSeachPage}
            disabled={isLoading || loading}
          >
            <IconButton
              tabIndex={-1}
              color="primary"
              sx={{ p: "0px", pr: "8px" }}
              aria-label="directions"
            >
              {isLoading || loading ? (
                <>
                  <CircularProgress color="inherit" size={isDesktop ? 40 : 32} />
                  <AlertMessageSROnly>
                    חיפוש בטעינה...
                  </AlertMessageSROnly>
                </>
              ) : (
                <SubmitSearchIcon
                  width={!isDesktop ? 32 : 40}
                  height={!isDesktop ? 32 : 40}
                  blue
                />
              )}
            </IconButton>
          </Button>
        </Paper>
        <StyledMenu
          id="autocomplete-results"
          anchorEl={paperRef.current}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          disableAutoFocus
          sx={{
            "& .MuiPaper-root": {
              width: paperRef.current ? `${paperRef.current.clientWidth}px` : "auto",
            },
          }}
          ref={menuRef}
        >
          {finalData.length > 0 && finalData.map((el, i) => (
            <Link
              key={i}
              style={{ textDecoration: "none", cursor: "pointer", width: '100%' }}
              href={getNonUmbracoURL(
                el.url.includes("home") ? "/" : el.url,
              )}
              passHref
              legacyBehavior
            >
              <MenuItem
                component={"a"}
                onClick={() => {
                  router.push(getNonUmbracoURL(
                    el.url.includes("home") ? "/" : el.url,
                  ))
                  clearHandle()
                  setSearchIsActive(false)
                  handleClose()
                }}
                tabIndex={0}
                role="link"
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === 'Escape') {
                    e.preventDefault();
                    handleClose();
                    inputRef.current?.focus();
                    return;
                  }
                  if (i === 0 && e.shiftKey) {
                    e.preventDefault()
                    handleClose()
                    inputRef.current?.focus();
                    return;
                  }
                  if (e.key === 'Tab') {
                    // Only handle special case for last item
                    if (i === finalData.length - 1 && !e.shiftKey) {
                      e.preventDefault();
                      handleClose();
                      inputRef.current?.focus();
                    }
                    // Remove any other Tab key handling to allow natural tab behavior
                  } else if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                    // Keep default MUI arrow key behavior
                    return;
                  }
                }}

              >

                <Result>{el.name}</Result>

              </MenuItem>
            </Link>
          ))}
          {finalData.length === 0 && <Result>אין תוצאות</Result>}
          {isSuccess && <AlertMessageSROnly>
            {`נמצאו ${finalData.length} תוצאות`}
          </AlertMessageSROnly>}
        </StyledMenu>
      </div>
    </>
  )
}

export default SearchAutocomplete
